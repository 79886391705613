import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from '../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Content from '../../components/articles/Content'
import NewsArticleLink from '../../components/articles/Listings/NewsArticleLink'
import Newsletter from '../../components/Newsletter'

export default function EventTemplate({ data }) {
  const { article, site, relatedArticles } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${article.categoryLink}/${article.slug}`
  )

  const breadcrumbs = [
    {
      name: 'Events',
      uri: '/events',
    },
    {
      name: article.category,
      uri: article.categoryLink,
    },
    {
      name: article.title,
      uri: `${article.slug}`,
    },
  ]

  return (
    <Page
      className="p-newsArticle p-articleEditorials has-fingerPrints"
      datoIndex
    >
      <HelmetDatoCms seo={article.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {article.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: article.canonicalUrl,
              href: article.canonicalUrl,
            },
          ]}
        />
      )}

      <article
        id={article.id}
        className="c-article"
        style={{
          background: 'linear-gradient(180deg, #fff 400px, #f8f8f8 400px)',
        }}
      >
        <div className="container py-5">
          <header className="c-articleFull">
            <HeaderInfo
              date={article.pubDateTime}
              category={article.category}
              categoryLink={article.categoryLink}
              hide="true"
            />

            <div className="c-articleFull__hero">
              <div className="c-eventHeroOverlay">
                <h1 className="c-eventHeroOverlay__title">{article.title}</h1>
              </div>
              <Img
                fluid={article.heroImage.fluid}
                title={article.heroImage.title}
                alt={article.heroImage.alt}
                className="c-articleFull__heroImage img-fluid"
              />
            </div>
          </header>

          {/* <!-- Intro --> */}
          <section>
            <div className="row">
              <div className="c-socialEvent col-12 col-lg-3">
                <div>
                  <ShareButtons shareUrl={shareUrl} />
                </div>
                <div className="c-articleEvent col-11">
                  <div className="c-articleEventItem">
                    <h3 className="c-articleEventItem__header">
                      <img
                        src="/icons/calander.svg"
                        className="c-icon c-icon--phone mr-1"
                      />{' '}
                      Date and time
                    </h3>
                    <p className="c-articleEventItem__text">
                      {article.eventDate}
                    </p>
                  </div>

                  <div className="c-articleEventItem">
                    <h3 className="c-articleEventItem__header">
                      <img
                        src="/icons/map-pointer-dark.svg"
                        className="c-icon c-icon--phone mr-1"
                      />{' '}
                      Location
                    </h3>
                    <p className="c-articleEventItem__text">
                      {article.address}
                      <br />
                      {article.city}
                      <br />
                      {article.postcode}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-9">
                {article.leadIn && (
                  <div className="row">
                    <div className="col-12">
                      <h2 className="c-articleLeadIn">{article.leadIn}</h2>
                      <hr />
                    </div>
                  </div>
                )}

                {/* <!-- Body --> */}
                <div className="row">
                  <div className="col-12">
                    <Content content={article.content} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related News
                </h3>
                <Link to="/news" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All News</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }) => (
                <div key={node.slug} className="col-6 col-lg-4">
                  <NewsArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query EventQuery($slug: String!) {
    article: datoCmsEventArticle(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      heroImage {
        fluid(imgixParams: { w: "1110", fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      eventDate(formatString: "MMMM Do, YYYY hh:mma")
      address
      city
      postcode
      leadIn
      content {
        ...ArticleContentFragment
      }
      eventForm
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    relatedArticles: allDatoCmsNewsArticle(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          heroImage {
            url
            alt
            title
            customData
          }
          thumbnailImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
