import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const UrlLinkBlock = ({ block }) => {
  const image = block.urlLink.thumbnail
  let src = `${image.url}?fit=crop`

  return (
    <div className="c-articleOfInterest mb-3" data-datocms-noindex>
      <p className="c-articleOfInterest__header c-articleOfInterest__header--sm">
        You may also be interested in…
      </p>
      <div className="c-articleOfInterestThumbnail c-articleOfInterestThumbnail--lg">
        <img
          className="img-fluid"
          src={`${src}&w=260&h=300`}
          title={image.title}
          alt={image.alt}
          loading="lazy"
        />
      </div>
      <div className="c-articleOfInterestThumbnail c-articleOfInterestThumbnail--sm">
        <picture loading="lazy">
          <source
            srcSet={`${src}&w=350&h=220&dpr=1 1x, ${src}&w=350&h=220&dpr=2 2x, ${src}&w=350&h=220&dpr=3 3x`}
            media="(min-width: 768px)"
          />
          <source
            srcSet={`${src}&w=450&h=290&dpr=1 1x`}
            media="(min-width: 400px)"
          />
          <img
            className="img-fluid"
            srcSet={`${src}&w=350&h=220&dpr=1 1x, ${src}&w=350&h=220&dpr=2 2x, ${src}&w=350&h=220&dpr=3 3x`}
            src={`${src}&w=350&h=220`}
            title={image.title}
            alt={image.alt}
            loading="lazy"
          />
        </picture>
      </div>
      <div className="c-articleOfInterestBody">
        <p className="c-articleOfInterest__header c-articleOfInterest__header--lg">
          You may also be interested in…
        </p>
        <div className="c-articleOfInterest__cta">
          <h4 className="c-articleOfInterest__cta__title">
            {block.urlLink.title}
          </h4>
          <a href={block.urlLink.url} className="c-button c-button--secondary">
            <ArrowRightSVG className="c-icon mr-2" />
            Read
          </a>
        </div>
      </div>
    </div>
  )
}

UrlLinkBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default UrlLinkBlock

export const FragmentQuery = graphql`
  fragment UrlLinkBlockFragment on DatoCmsUrlLinkBlock {
    id
    model {
      apiKey
    }
    urlLink {
      title
      url
      thumbnail: thumbnailImage {
        title
        alt
        url
      }
    }
    blockSize
  }
`
