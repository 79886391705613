import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const Video = ({ video }) => (
  <div className="embed-responsive embed-responsive-16by9">
    {video.provider === 'youtube' && (
      <iframe
        src={`https://www.youtube.com/embed/${video.providerUid}`}
        frameBorder="0"
        allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    )}
    {video.provider === 'vimeo' && (
      <iframe
        src={`https://player.vimeo.com/video/${video.providerUid}`}
        frameBorder="0"
        allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    )}
  </div>
)

Video.propTypes = {
  video: PropTypes.object.isRequired,
}

export default Video

export const FragmentQuery = graphql`
  fragment VideoFragment on DatoCmsVideoField {
    provider
    providerUid
  }
`
