import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import TextBlock from './blocks/TextBlock'
import ImageBlock from './blocks/ImageBlock'
import ExternalVideoBlock from './blocks/ExternalVideoBlock'
import ArticleLinkBlock from './blocks/ArticleLinkBlock'
import UrlLinkBlock from './blocks/UrlLinkBlock'

const Content = ({ content }) => (
  <div className="c-articleContent row">
    {content.map((block) => {
      let classCol = `c-articleContentCol col-12`

      switch (block.blockSize) {
        case '12/12':
          classCol = `${classCol} col-lg-12`
          break
        case '9/12':
          classCol = `${classCol} col-lg-9`
          break
        case '8/12':
          classCol = `${classCol} col-lg-8`
          break
        case '7/12':
          classCol = `${classCol} col-lg-7`
          break
        case '6/12':
          classCol = `${classCol} col-lg-6`
          break
        case '5/12':
          classCol = `${classCol} col-lg-5`
          break
        case '4/12':
          classCol = `${classCol} col-lg-4`
          break
        case '3/12':
          classCol = `${classCol} col-lg-3`
          break
      }

      return (
        <div key={block.id} className={classCol}>
          {block.model.apiKey === 'text_block' && <TextBlock block={block} />}
          {block.model.apiKey === 'image_block' && <ImageBlock block={block} />}
          {block.model.apiKey === 'external_video_block' && (
            <ExternalVideoBlock block={block} />
          )}
          {block.model.apiKey === 'article_link_block' && (
            <ArticleLinkBlock block={block} />
          )}
          {block.model.apiKey === 'url_link_block' && (
            <UrlLinkBlock block={block} />
          )}
        </div>
      )
    })}
  </div>
)

Content.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Content

export const FragmentQuery = graphql`
  fragment ArticleContentFragment on DatoCmsUnionForDatoCmsNewsArticleContent {
    ...TextBlockFragment
    ...ImageBlockFragment
    ...ExternalVideoBlockFragment
    ...ArticleLinkBlockFragment
    ...UrlLinkBlockFragment
  }
`
