import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import rehypeReact from 'rehype-react'

import ReLink from '../../rehype/Link'
import ReTable from '../../rehype/Table'

const TextBlock = ({ block }) => {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { a: ReLink, table: ReTable },
  }).Compiler

  return renderAst(block.textNode.childMarkdownRemark.htmlAst)
}

TextBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default TextBlock

export const FragmentQuery = graphql`
  fragment TextBlockFragment on DatoCmsTextBlock {
    id
    model {
      apiKey
    }
    textNode {
      childMarkdownRemark {
        htmlAst
      }
    }
    blockSize
  }
`
