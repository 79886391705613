import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ArticleLink from './../ArticleLink'

const ArticleLinkBlock = ({ block }) => (
  <ArticleLink article={block.articleLink} />
)

ArticleLinkBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default ArticleLinkBlock

export const FragmentQuery = graphql`
  fragment ArticleLinkBlockFragment on DatoCmsArticleLinkBlock {
    id
    model {
      apiKey
    }
    articleLink {
      ...NewsArticleLinkFragment
      ...EditorialArticleLinkFragment
      ...DatapolArticleLinkFragment
      ...RecipeArticleLinkFragment
    }
    blockSize
  }
`
