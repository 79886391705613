import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Video from './../Video'

const ExternalVideoBlock = ({ block }) => (
  <div className="c-articleVideo" data-datocms-noindex>
    <Video video={block.video} />
  </div>
)

ExternalVideoBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default ExternalVideoBlock

export const FragmentQuery = graphql`
  fragment ExternalVideoBlockFragment on DatoCmsExternalVideoBlock {
    id
    model {
      apiKey
    }
    video {
      ...VideoFragment
    }
    blockSize
  }
`
