import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageBlock = ({ block }) => {
  const showCaption = block.caption && block.image.title

  return (
    <figure className="c-articleFigure">
      <Img
        fluid={block.image.fluid}
        title={block.image.title}
        alt={block.image.alt}
        style={{ width: `${block.image.width}px` }}
        className={`c-articleImage ${
          block.dropShadow && 'c-articleImage--dropShadow'
        }`}
      />

      {showCaption && (
        <figcaption className="c-articleFigure__caption text-muted">
          {block.image.title}
        </figcaption>
      )}
    </figure>
  )
}

ImageBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default ImageBlock

export const FragmentQuery = graphql`
  fragment ImageBlockFragment on DatoCmsImageBlock {
    id
    model {
      apiKey
    }
    image {
      title
      alt
      width
      fluid(imgixParams: { w: "850", fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    caption
    dropShadow
    blockSize
  }
`
